<template>
  <side-popup @close="$emit('close')">
    <div class="head">
      <button v-if="!user" class="inline-button main-accent" @click="switchMode">
        <v-svg name="list-check" />
        <span v-if="bulkMode">Habilitation unique</span>
        <span v-else>Habilitation en masse</span>
      </button>
      <div v-else />
      <button class="inline-button destructive" @click="$emit('close')"><v-svg name="close" />Fermer</button>
    </div>
    <div class="content">
      <template v-if="user">
        <div class="user">
          <div class="infos">
            <div class="name">{{ user.displayName }}</div>
            <div class="email">{{ user.mail }}</div>
            <div class="uid">{{ user.uid }}</div>
            <div v-if="user.iirBusinessUnit" class="bu">BU {{ user.iirBusinessUnit }}</div>
            <div v-if="user.type" class="type">{{ user.type }}</div>
          </div>
        </div>
        <div class="roles">
          <template v-for="[app, roles] in Object.entries(appRoles)" :key="app">
            <div class="logo"><app-logo icon="universal" class="bg-grey" :application="app" /> {{ getAppById(app)?.name ?? app }}</div>
            <div class="roles-list">
              <button
                v-for="[r, source] of Object.entries(roles)"
                :key="`${app}${r}`"
                v-tooltip.bottom="getRoleTooltip(source, app)"
                :class="[
                  'main-button role ',
                  {
                    'second-accent': source === 'userRoles' && !roleLockedByUser(app),
                    'active-forbidden': source && (roleLockedByUser(app) || roleLockedBySource(source)),
                    forbidden: roleLockedBySource(source) || roleLockedByUser(app),
                    grey: !source
                  }
                ]"
                @click="!roleLockedBySource(source) && !roleLockedByUser(app) && toogleRole(app, r)"
              >
                {{ r }}
              </button>
            </div>
          </template>
        </div>
        <search-app @select="addApp" />
      </template>
      <template v-else>
        <h1>Nouvelle habilitation {{ bulkMode ? 'en masse' : 'unique' }}</h1>
        <search-user :bulk="bulkMode" @select="selectUser" />
        <template v-if="!bulkMode">
          <div class="separator">OU <div class="line" /></div>
          <search-bu @select="selectUser" />
          <div class="separator">OU <div class="line" /></div>
          <search-job @select="selectUser" />
          <div class="separator">OU <div class="line" /></div>
          <button
            v-if="isSuperAdmin"
            class="main-button second-accent"
            @click="selectUser({ displayName: 'EVERYBODY', uid: `group:EVERYBODY`, mail: `group:EVERYBODY` })"
          >
            <v-svg name="list-check" />Habiliter tout le monde
          </button>
        </template>
        <template v-else>
          <div v-for="u in bulkUsers" :key="u.uid" class="user">
            <div class="infos">
              <div class="name">{{ u.displayName }}</div>
              <div class="email">{{ u.mail }}</div>
              <div class="uid">{{ u.uid }}</div>
            </div>
            <div class="remove">
              <v-svg h="2rem" name="remove" @click="removeBulkUser(u.mail)" />
            </div>
          </div>
          <div v-if="bulkApp" class="roles">
            <div class="logo"><app-logo icon="universal" class="bg-grey" :application="bulkApp" /> {{ getAppById(bulkApp)?.name ?? bulkApp }}</div>
            <div class="roles-list">
              <button
                v-for="r in getAppById(bulkApp)?.roles"
                :key="`${bulkApp}${r}`"
                :class="['main-button role', bulkRoles[bulkApp].includes(r) ? 'second-accent' : 'grey']"
                @click="toogleRole(bulkApp, r)"
              >
                {{ r }}
              </button>
            </div>
          </div>
          <div v-if="bulkUsers.length" class="bulk-roles">
            <search-app @select="addApp" />
          </div>
        </template>
      </template>
    </div>
    <template #footer>
      <div v-if="showSaveButton" class="footer">
        <div class="save" @click="save"><v-svg name="save" />Enregistrer </div>
      </div>
    </template>
  </side-popup>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import useAuthorizationStore from '@/store/authorization.store'
  import useApplicationStore from '@/store/application.store'
  import { globalProperties } from '@/main'

  export default {
    name: 'AuthorizationEditor',
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    emits: ['close', 'selectUser', 'fetchUsers'],
    setup(props, context) {
      const authorizationStore = useAuthorizationStore()
      const applicationStore = useApplicationStore()
      const { getAppById } = applicationStore

      /* USER(S) */
      const bulkMode = ref(false)
      const bulkUsers = ref([])
      const bulkRoles = ref(null)
      const bulkApp = computed(() => (bulkRoles.value ? Object.keys(bulkRoles.value)[0] : null))

      const switchMode = () => {
        bulkMode.value = !bulkMode.value
      }

      const removeBulkUser = mail => {
        bulkUsers.value = bulkUsers.value.filter(u => u.mail !== mail)
      }

      const userRoles = ref(null)
      const newRoles = ref(null)

      watch(
        () => props.user,
        async () => {
          if (props.user) {
            userRoles.value = await authorizationStore.getUserRoles(props.user)
            newRoles.value = { ...userRoles.value.userRoles }
          }
        },
        { immediate: true }
      )

      const selectUser = user => {
        if (bulkMode.value) {
          if (bulkUsers.value.findIndex(u => u.mail === user.mail) < 0) bulkUsers.value.push(user)
        } else context.emit('selectUser', user)
      }

      /* ROLES */

      const roleLockedBySource = source => source && source !== 'userRoles'

      const isSuperAdmin = computed(() => globalProperties.roles.global?.includes('super-admin'))

      const roleLockedByUser = app => {
        if (isSuperAdmin.value) return false
        else return applicationStore.applications.value.findIndex(a => a.id === app) < 0
      }

      const getAppRoles = id => (id === 'global' ? ['admin', 'super-admin'] : getAppById(id)?.roles)

      const getRoleSource = (appId, role) => allRoles.value?.[appId]?.[role] ?? null

      const appRoles = computed(() => {
        if (bulkMode.value) {
          if (!bulkRoles.value) return {}
          const appId = Object.keys(bulkRoles.value)[0]
          const roles = getAppRoles(appId)
          return { [appId]: Object.fromEntries(roles.map(r => [r, null])) }
        } else {
          if (!userRoles.value) return {}
          return Object.fromEntries(
            Object.values(userRoles.value)
              .map(apps => Object.keys(apps ?? {}))
              .flat()
              .sort()
              .map(appId => {
                const roles = getAppRoles(appId) ?? []
                return [appId, Object.fromEntries(roles.map(r => [r, getRoleSource(appId, r)]))]
              })
          )
        }
      })

      const allRoles = computed(() => {
        if (!userRoles.value) return {}
        const roles = {}

        Object.entries(userRoles.value).forEach(([source, apps]) => {
          Object.entries(apps ?? {}).forEach(([app, appRoles]) => {
            roles[app] ??= {}
            appRoles.forEach(role => {
              roles[app][role] = source
            })
          })
        })

        return roles
      })

      const toogleRole = (app, role) => {
        if (!bulkMode.value) {
          newRoles.value[app] ??= []
          const actualRoles = newRoles.value[app]
          newRoles.value[app] = actualRoles.includes(role) ? actualRoles.filter(r => r !== role) : actualRoles.concat(role)
          userRoles.value.userRoles = newRoles.value
        } else {
          const actualRoles = bulkRoles.value[app]
          bulkRoles.value[app] = actualRoles.includes(role) ? actualRoles.filter(r => r !== role) : actualRoles.concat(role)
        }
      }

      const getRoleTooltip = (source, app) => {
        if (roleLockedByUser(app)) return "Tu n'es pas responsable de cette app"
        switch (source) {
          case 'everybodyRoles':
            return 'Role attribué à tout les collaborateur'
          case 'buRoles':
            return `Habilité par la BU ${props.user.iirBusinessUnit}`
          case 'jobsRoles':
            return `Habilité par la fonction`
          case 'userRoles':
          default:
            return null
        }
      }

      const addApp = ({ id: appId }) => {
        if (bulkMode.value) {
          bulkRoles.value = { [appId]: [] }
        } else {
          if (newRoles.value[appId]) return
          newRoles.value[appId] = ref([])
          userRoles.value.userRoles = newRoles.value
        }
      }

      /* SAVE */
      const showSaveButton = computed(() => (bulkMode.value ? bulkRoles.value?.[bulkApp.value]?.length : !!newRoles.value))

      const save = async () => {
        if (!bulkMode.value)
          await authorizationStore.setUser(props.user.mail, { roles: newRoles.value, displayName: props.user.displayName, type: props.user.type })
        else {
          const promises = bulkUsers.value.map(user => authorizationStore.setUser(user.mail, { roles: bulkRoles.value }, true))
          await Promise.all(promises)
        }

        authorizationStore.resetAuthorizations()
        context.emit('fetchUsers')

        context.emit('close', true)
      }

      return {
        addApp,
        allRoles,
        appRoles,
        bulkApp,
        bulkMode,
        bulkRoles,
        bulkUsers,
        getAppById,
        getAppRoles,
        getRoleTooltip,
        isSuperAdmin,
        removeBulkUser,
        roleLockedBySource,
        roleLockedByUser,
        save,
        selectUser,
        showSaveButton,
        switchMode,
        toogleRole
      }
    }
  }
</script>

<style lang="scss" scoped>
  .head {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 24px;
  }
  .content {
    h1 {
      @extend %font-title-1;
    }
    .separator {
      width: 50%;
      color: var(--color-gray);
      display: grid;
      margin: 0.5rem 0;
      grid-template-columns: auto 1fr;
      align-items: center;
      column-gap: 0.5rem;
      .line {
        background: var(--color-gray);
        height: 1px;
      }
    }
    .user {
      margin: 1rem 0;
      display: flex;
      justify-content: space-between;
      .name {
        @extend %font-title-1;
      }
      .email {
        @extend %font-body-bold;
      }
      .uid,
      .bu,
      .type {
        color: hsla(var(--hsla-black), 0.5);
      }
      .remove {
        display: flex;
        align-items: center;
        color: var(--color-destructive);
        .svg-remove {
          cursor: pointer;
        }
      }
    }
    .roles {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 2rem 1.5rem;
      margin-bottom: 2rem;
      .logo {
        display: flex;
        align-items: center;
        .app-logo {
          margin-right: 0.6em;
        }
      }
      .roles-list {
        display: flex;
        align-items: center;
        > .role {
          margin-right: 16px;
          &.forbidden {
            cursor: not-allowed;
          }
          &.active-forbidden {
            background: var(--color-main-accent);
          }
        }
      }
    }
  }
  .footer {
    height: 44px;
    color: var(--color-white);
    .save {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: var(--color-second-accent);
      transition: background 200ms;
      &:hover {
        background: var(--color-second-accent-darker);
      }
    }
  }
</style>
