<template>
  <div v-if="!appSelected" class="select-app">
    Merci de selectionner une application
  </div>
  <div v-else class="app-authorization">
    <div class="header">
      <div class="product-owner">
        Product Owner : <span class="name">{{ productOwner.displayName }}</span> | {{ productOwner.mail }}
      </div>
      <button class="second-button second-accent" @click="exportUsers"> <v-svg h="1.5em" name="download" /> Exporter</button>
    </div>
    <div class="roles">
      <div v-for="role in roles" :key="role" class="role">
        <div class="role-header">
          <div class="role-name" @click="hideRole(role)">
            {{ role }} <v-svg :class="{ closed: hiddenRoles.includes(role) }" name="chevron-up" h="1.5em" />
          </div>
          <template v-if="addMode === role">
            <search-user @select="addRoleToUser($event, appId, role)" />
            <button class="inline-button second-accent" @click="addMode = null">Annuler</button>
          </template>
          <button v-else class="inline-button second-accent" @click="addMode = role"><v-svg h="1.5em" name="add" /> Ajouter</button>
        </div>
        <template v-if="!hiddenRoles.includes(role)">
          <div v-if="!loading" class="users">
            <div class="user" v-for="user in getUsersByRole(appId, role)" :key="user.mail">
              <div :class="['name', { red: !user.uid }]">
                {{ user.displayName }}
              </div>
              <div :class="['email', { red: !user.uid }]">
                {{ user.mail }}
              </div>
              <div class="uid">{{ user.uid }}</div>
              <div class="actions">
                <button v-if="user.uid" class="inline-button main-accent" @click="$emit('edit', user)">
                  <v-svg name="edit" h="1.4em" />Modifier
                </button>
                <button class="inline-button destructive" @click="deleteRole(user, appId, role)"> <v-svg name="delete" h="1.4em" />Supprimer </button>
              </div>
            </div>
          </div>
          <div v-else>
            Loading...
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import useApplicationStore from '@/store/application.store'
  import useAuthorizationStore from '@/store/authorization.store'
  import { computed, ref, watch } from 'vue'
  import { globalProperties } from '@/main'

  export default {
    name: 'AppAuthorization',
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['edit'],
    setup() {
      const applicationStore = useApplicationStore()
      const { selectedApp, selectedAppId } = applicationStore
      const authorizationStore = useAuthorizationStore()
      const { getUsersByRole } = authorizationStore

      const productOwner = computed(() => selectedApp.value?.productOwner ?? { mail: 'Non défini' })

      const roles = computed(() => (selectedApp.value ?? { roles: ['admin', 'super-admin'] }).roles)
      const appId = computed(() => selectedAppId.value ?? 'global')

      const hiddenRoles = ref([])
      const hideRole = role => {
        const hidden = hiddenRoles.value
        hiddenRoles.value = hidden.includes(role) ? hidden.filter(r => r !== role) : hidden.concat(role)
      }
      watch(selectedAppId, () => (hiddenRoles.value = []))

      const deleteRole = (user, appId, role) => {
        if (!user.uid) {
          if (confirm(`Supprimer l'utilisateur ${user.mail} ?`)) authorizationStore.deleteUser(user.mail)
        } else {
          if (confirm(`Supprimer le role ${role} pour ${user.displayName} ?`)) authorizationStore.deleteUserRole(user.mail, appId, role)
        }
      }

      const addMode = ref(null)
      const addRoleToUser = ({ mail }, appId, role) => {
        authorizationStore.addUserRole(mail, appId, role)
      }

      const exportUsers = async () => {
        let rows = []

        await roles.value.forEach(async role => {
          await authorizationStore.getUsersByRole(appId.value, role).forEach(user => {
            rows.push([user.displayName, user.uid, user.mail, user.employeeType, role])
          })
        })

        rows = rows.sort((a, b) => (a[2] < b[2] ? -1 : 1))
        rows.unshift([selectedApp.value?.name ?? 'Global'], ['Nom', 'Matricule', 'Email', 'Type', 'Role'])

        const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(row => row.join(',')).join('\n')
        var encodedUri = encodeURI(csvContent)
        window.open(encodedUri)
      }

      const appSelected = computed(() => {
        return globalProperties.roles.global?.includes('super-admin') || !!selectedApp.value
      })

      return {
        addMode,
        addRoleToUser,
        appId,
        appSelected,
        productOwner,
        deleteRole,
        exportUsers,
        getUsersByRole,
        hiddenRoles,
        hideRole,
        roles
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-authorization {
    background: var(--color-white);
    box-shadow: var(--shadow-default);
    padding: 2rem;
    border-radius: 1rem;
    .header {
      display: flex;
      justify-content: space-between;
      .product-owner {
        .name {
          @extend %font-body-bold;
        }
      }
    }
    .roles {
      .search-user {
        width: 400px;
      }
      .role {
        margin-bottom: 64px;
        .role-header {
          display: flex;
          align-items: center;
          top: 0;
          background: var(--color-white);
          .role-name {
            display: flex;
            align-items: center;
            @extend %font-title-4;
            .v-svg {
              margin-left: 0.4em;
              &.closed {
                transform: rotate(180deg);
              }
            }
          }
        }

        .users {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr auto;
          .red {
            color: var(--color-destructive);
          }

          .user {
            display: contents;

            > * {
              display: flex;
              align-items: center;
              border-bottom: 1px solid hsla(var(--hsla-black), 0.1);
              height: 100%;
            }
            &:hover > * {
              background: hsla(var(--hsla-black), 0.1);
            }
          }

          .name {
            @extend %font-body-bold;
          }
          .actions {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
</style>
