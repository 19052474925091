<template>
  <div class="top">
    <search-user class="search-user" @select="openEditor" />
    <div class="new-authorization" @click="editorOpened = !editorOpened">
      <v-svg name="list-check" />
      <span>Nouvelle habilitation</span>
    </div>
  </div>
  <app-selector />
  <div class="authorizations-view">
    <authorization-demands @edit="openEditor" />
    <app-authorization :loading="loading" @edit="openEditor" />
  </div>
  <authorization-editor v-if="editorOpened"
                        :user="editionItem"
                        @fetchUsers="fetchRolesUsers"
                        @selectUser="openEditor"
                        @close="closeEditor" />
</template>

<script>
  import AuthorizationDemands from '@/components/authorization/AuthorizationDemands.vue'
  import AuthorizationEditor from '@/components/authorization/AuthorizationEditor.vue'
  import SearchUser from '@/components/base/SearchUser.vue'
  import AppSelector from '@/components/AppSelector.vue'
  import AppAuthorization from '@/components/authorization/AppAuthorization'
  import useModalEditor from '@/mixins/modalEditor'
  import useApplicationStore from '@/store/application.store'
  import useAuthorizationStore from '@/store/authorization.store'
  import { ref, watch } from 'vue'

  export default {
    name: 'Authorizations',
    components: {
      AuthorizationDemands,
      AuthorizationEditor,
      SearchUser,
      AppSelector,
      AppAuthorization
    },
    setup() {
      const modalEditor = useModalEditor()

      const applicationStore = useApplicationStore()
      const { selectedApp, selectedAppId } = applicationStore
      const authorizationStore = useAuthorizationStore()

      const loading = ref(true)

      const fetchRolesUsers = async() => {
        loading.value = true
        const promises = (selectedApp.value?.roles ?? ['admin', 'super-admin']).map(role =>
          authorizationStore.fetchRolesUsers(selectedAppId.value ?? 'global', role)
        )
        await Promise.all(promises)
        loading.value = false
      }

      watch(selectedApp, fetchRolesUsers, { immediate: true })

      return {
        ...modalEditor,
        loading,
        fetchRolesUsers
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem;
    .new-authorization {
      color: var(--color-white);
      background-color: var(--color-second-accent-darker);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
    }
  }
  .search-user {
    width: 28vw;
  }
  .authorizations-view {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
</style>
