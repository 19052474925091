<template>
  <div v-if="demands.length" class="authorization-demands">
    <span class="title">Demandes d'habilitation</span>
    <div v-if="!loading">
      <div class="demands">
        <template v-for="user in demands" :key="user.mail">
          <div :class="['name', { red: !user.uid }]">
            {{ user.displayName ?? 'INCONNU DU LDAP' }}
          </div>
          <div :class="['email', { red: !user.uid }]">
            {{ user.mail ?? user.email }}
          </div>
          <div class="uid">{{ user.uid }}</div>
          <div class="actions">
            <button v-if="user.uid" class="inline-button main-accent" @click="$emit('edit', user)">
              <v-svg name="checkmark" h="1.4em" />Habiliter
            </button>
            <button class="inline-button destructive" @click="refuseDemand(user.email, user.app)"><v-svg name="close" h="1.4em" />Supprimer</button>
          </div>
          <div class="message">
            {{ user.message }}
          </div>
          <div class="border" />
        </template>
      </div>
    </div>
    <div v-else> ...loading </div>
  </div>
</template>

<script>
  import useAuthorizationStore from '@/store/authorization.store'
  import useApplicationStore from '@/store/application.store'
  import { computed, watch, ref } from 'vue'

  export default {
    name: 'AuthorizationDemands',
    emits: ['edit'],
    setup() {
      const applicationStore = useApplicationStore()
      const authorizationStore = useAuthorizationStore()
      const { selectedApp, selectedAppId } = applicationStore

      const loading = ref(true)
      const demands = ref([])

      const appId = computed(() => selectedAppId.value ?? 'global')

      watch(
        selectedApp,
        async() => {
          loading.value = true
          demands.value = await authorizationStore.getDemands(appId.value)
          loading.value = false
        },
        { immediate: true }
      )

      const refuseDemand = (email, app) => {
        authorizationStore.deleteDemand(email, app)
      }

      return { demands, refuseDemand, loading }
    }
  }
</script>

<style lang="scss" scoped>
  .authorization-demands {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    background-color: var(--color-white);
    padding: 2rem;
    .title {
      font-size: 1.3rem;
      font-weight: bold;
    }
    .demands {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr auto;
      .red {
        color: var(--color-destructive);
      }
      > * {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .message {
        grid-column: 1 / 4;
        color: hsla(var(--hsla-black), 0.5);
        padding-bottom: 0.3rem;
      }
      .border,
      .message {
        border-bottom: 1px solid hsla(var(--hsla-black), 0.1);
      }
      .name {
        @extend %font-body-bold;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
